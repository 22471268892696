body{
    color: $textColor;
}

a{
    color: $textColor;
    &:hover{
        color: $primaryColor;
    }
}


.btn-mitra.btn-secondary{
    background-color: $secondaryColor !important;
    border-color:$secondaryColor !important;
}

.btn-mitra.btn.btn-outline-primary, .btn-mitra.btn.btn-outline-primary:hover{
    border-color: $primaryColor !important;
    color: $primaryColor !important;
}

.btn-mitra.btn.btn-primary.active {
    background-color: #03386c !important;
}

.bg-primary, .inn-title{
    background-color: $primaryColor !important;
}

.dropdown-item{
    &:active{
        outline:none !important;
        background-color: $primaryColor !important;
        color: #fff !important;
    }
}

.btn-mitra.btn-primary{
 @include  btn($primaryColor, #fff);
}

.text-primary, a.text-primary:focus, a.text-primary:hover{
    color: $primaryColor!important;
}


.btn-login{
    @include  btn($primaryColor, #fff);
}

.btn-noti span{
    background-color: $primaryColor !important;
    border-color: $primaryColor !important;
    box-shadow: 0 0 0 1px $primaryColor !important;
}

.top-not-cen{
    >a{
        &:hover{
            color:$textColor !important;
        }
    }
}