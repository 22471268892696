@import url('https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700');
/* :: 2.0 Import All CSS */
// @import url(assets/css/bootstrap.min.css);
@import url(../css/classy-nav.css);
@import url(../css/font-awesome.min.css);
@import url(../css/fontawesome.min.css);
@import url(../css/themify-icons.css);
@import url(../css/owl.carousel.min.css);
@import url(../css/animate.css);
@import url(../css/magnific-popup.css);

/* Importing Bootstrap SCSS file. */
// @import "~bootstrap/dist/css/bootstrap.min.css";
@import '~@swimlane/ngx-datatable/assets/icons.css';
@import '~@swimlane/ngx-datatable/themes/bootstrap.css';
@import '~@swimlane/ngx-datatable/index.css';
@import '~ngx-toastr/toastr.css';
@import '../../../node_modules/flatpickr/dist/flatpickr.css';
@import '../../../node_modules/ngx-bootstrap/datepicker/bs-datepicker.scss';

/* Import AG-Grid */
@import "@ag-grid-community/core/dist/styles/ag-grid.css";
@import "@ag-grid-community/core/dist/styles/ag-theme-alpine.css";
@import "@ag-grid-community/core/dist/styles/ag-theme-balham.css";

@import './main.scss';
// patient video
// button.btn.btn-primary.pl-3.pr-3.align {
//   background: #ffffff12 !important;
//   border: none !important;
// }
.ngx-pagination {
  margin-left: 0;
  margin-bottom: 1rem;
  text-align: end;
  margin-top: 1rem;
} 


ngb-timepicker{
  .btn{
    display: none !important;
  }
}

div#addnewcard {
  background: #1919193d;
}
.hideUPdown{
  .btn.btn-link{
    display: none !important;
  }
}

.modal, .pac-container {
  z-index: 999999 !important;
}

.pac-container {
  padding-top: 8rem;
}

.breadcrumb {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0;
  margin-bottom: 4px;
  list-style: none;
  background-color: #ddf2ff;
  border-radius: .25rem;
}
/* 
.modal-dialog.modal-lg.big {
    position: initial !important;
}
.modal-dialog.modal-lg {
    position: absolute;
    right: 10rem;
} */
.small {
    position: absolute;
    top: -9.5rem !important;
}
img.stop-rec {
  width: 4rem !important;
}
img.stop-rec {
  width: 4rem;
}
button.btn.btn-primary.pl-3.pr-3.alignStop {
    background: #2d9ee8 !important;
    border: none !important;
}
// video css

.videoAlign div.OT_root.OT_subscriber.OT_fit-mode-cover.OT_micro.OT_mini {
  height: 34rem !important;
  width: 64rem !important;
  position: absolute;
  right: 0rem;
}
.OT_publisher .OT_edge-bar-item.OT_mode-off,
.OT_subscriber .OT_edge-bar-item.OT_mode-off,
.OT_publisher .OT_edge-bar-item.OT_mode-auto,
.OT_subscriber .OT_edge-bar-item.OT_mode-auto,
.OT_publisher .OT_edge-bar-item.OT_mode-mini-auto,
.OT_subscriber .OT_edge-bar-item.OT_mode-mini-auto {
  top: -25px;
  opacity: 0;
  right: 5rem;
}
.videoAlign div.OT_root.OT_subscriber.OT_fit-mode-cover {
  height: 34rem !important;
  width: 64rem !important;
  position: absolute;
  right: 0rem;
  border: 4px solid #000;
}
.videoAlign
  div.OT_mirrored.OT_root.OT_publisher.OT_fit-mode-cover.OT_micro.OT_mini {
  height: 15rem !important;
  width: 15rem !important;
  position: absolute;
  right: 0;
}
.videoAlign div.OT_mirrored.OT_root.OT_publisher.OT_fit-mode-cover {
  height: 15rem !important;
  width: 15rem !important;
  position: absolute;
  right: 0;
}

// video css
.OT_mirrored.OT_root.OT_publisher.OT_fit-mode-cover {
  width: 5rem !important;
  height: 5rem !important;
  position: absolute;
  right: 4px;
  top: 68px;
  border: 3px solid #fff;
}
img.start-rec {
  width: 100%;
  max-width: 4rem;
}
.OT_mirrored.OT_root.OT_publisher.OT_fit-mode-cover.OT_micro.OT_mini {
  width: 13rem !important;
  height: 13rem !important;
  position: absolute;
  right: 0;
}
.OT_root.OT_subscriber.OT_fit-mode-cover.OT_micro.OT_mini {
  width: 13rem !important;
  height: 13rem !important;
  position: absolute;
  right: 0;
  bottom: 10rem;
  // z-index: 99999999999999999999999 !important;
}
.OT_root.OT_subscriber.OT_fit-mode-cover {
  width: 100% !important;
  height: 10rem !important;
  position: absolute;
  right: 0;
  bottom: 0rem;
  // z-index: 100000000000000030000000 !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.ngx-datatable
  .datatable-header
  .datatable-header-cell.resizeable:hover
  .resize-handle,
.ngx-datatable
  .datatable-header
  .datatable-header-cell:hover
  .resize-handle--not-resizable {
  visibility: hidden !important;
}
.large {
  position: absolute !important;
  width: 990px !important;
  right: 0px !important;
  height: 50rem !important;
  left: inherit !important;
}
#publisher {
  position: absolute;
  width: 214px;
  height: 170px;
  bottom: -6px;
  // left: -5px;
  top: 0;
  right: 0px !important;
  z-index: 100;
  border: 3px solid #ffffff00;
  border-radius: 3px;
}
/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.modal-backdrop{
  z-index: 999999;
}

.swal2-container.swal2-center.swal2-backdrop-show {
  z-index: 999999 !important;
}

#toast-container {
  z-index: 9999999 !important;
}

.isfull .main_sidebar {
  width: 0px !important;
}
.isfull .main-pannel {
  width: 100%;
  margin-left: 0%;
}
.sider-bar.hide.tooglebutton {
  left: 2rem !important;
}
.cal-month-view .cal-day-cell {
  min-height: 50px !important;
}
.cal-month-view .cal-cell-top {
  min-height: 55px;
  flex: 1;
}
.cal-events {
  display: none !important;
}
.loader-logo {
  max-width: 80px;
}
.empty-row {
  text-align: center;
  color: red;
}
input:-internal-autofill-selected {
  background-color: #ffff !important;
}
// .datatable-body-cell-label {
//   white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;
// }
.form-control {
  background-color: #fff !important;
  &.disabled {
    pointer-events: none;
    background-color: #e8f0fe !important;
    border-color: #e8f0fe !important;
  }
}

.input_warpper {
  position: relative;

  > input {
    padding-right: 55px !important;
  }

  .checksign {
    position: absolute;
    right: 1px;
    top: 1px;
    width: 50px;
    height: calc(100% - 2px);
    display: flex;
    align-items: center;
    border: 3px solid transparent;
    justify-content: center;
    background-color: #4caf50;
    color: #fff;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    cursor: pointer;

    &.disabled {
      cursor: initial;
      opacity: 0.8;
      pointer-events: none;
      background-color: #5f5f5f;
    }
  }
}

.button-cover {
  display: inline-block;
  .knobs,
  .layer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .button {
    position: relative;
    width: 74px;
    height: 36px;
    overflow: hidden;
  }

  .button.r,
  .button.r .layer {
    border-radius: 100px;
  }

  .button.b2 {
    border-radius: 2px;
  }

  .checkbox {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
  }

  .knobs {
    z-index: 2;
  }

  .layer {
    width: 100%;
    background-color: #ebf7fc;
    transition: 0.3s ease all;
    z-index: 1;
  }
}

/* Button 1 */
#button-1 .knobs:before {
  content: 'YES';
  position: absolute;
  top: 4px;
  left: 4px;
  width: 28px;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  padding: 9px 4px;
  background-color: #03a9f4;
  border-radius: 50%;
  transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
}

#button-1 .checkbox:checked + .knobs:before,
.button-cover .button.active .knobs:before {
  content: 'NO' !important;
  left: 42px !important;
  background-color: #f44336 !important;
}

#button-1 .checkbox:checked ~ .layer,
.button-cover .button.active .layer {
  background-color: #fcebeb !important;
}

#button-1 .knobs,
#button-1 .knobs:before,
#button-1 .layer {
  transition: 0.3s ease all;
}

.invalid-feedback {
  display: block;
}

.datatable-body-cell-label {
  width: 100%;
  p {
    display: inline;
  }

  span {
    width: 100%;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

bs-datepicker-container,
bs-daterangepicker-container {
  z-index: 999999;
}

bs-datepicker-container {
  .theme-green .bs-datepicker-head,
  .theme-green .bs-datepicker-body table td span.selected,
  .theme-green .bs-datepicker-body table td.selected span,
  .theme-green .bs-datepicker-body table td span[class*='select-']:after,
  .theme-green .bs-datepicker-body table td[class*='select-'] span:after {
    background-color: $primaryColor;
  }

  .theme-green .bs-datepicker-body table td.week span {
    color: $primaryColor;
  }
}

.page-item.active .page-link {
  background-color: #195997 !important;
  border-color: #195997 !important;
  color: #fff !important;
}

.events_datatable {
  .datatable-row-center {
    datatable-header-cell {
      &:last-child {
        width: 400px !important;
      }
    }

    datatable-body-cell {
      &:last-child {
        width: 400px !important;
      }
    }
  }
}

.products_datatable,
.users_datatable {
  .datatable-row-center {
    datatable-header-cell {
      &:last-child {
        width: 250px !important;
      }
    }

    datatable-body-cell {
      &:last-child {
        width: 250px !important;
      }
    }
  }
}

.modal,
.pac-container {
  z-index: 99999;
}

.cal-day-cell {
  outline: none !important;
}

.cal-open-day-events {
  padding: 0 !important;

  div[dragactiveclass='cal-drag-active'] {
    padding: 7px 12px !important;
  }
}

.cal-has-events {
  background-color: $primaryColor !important;
  color: #fff !important;

  .cal-day-number {
    color: #fff !important;
    opacity: 1;
  }
}

ngx-spinner {
  position: relative;
  z-index: 99999999;
}

.modal.fade.d-block {
  display: block;
  overflow-y: auto;
  background-color: #00000061;
}

.ticket_list_table .datatable-row-group datatable-body-cell:nth-child(5),
datatable-header-cell[title='Start Date'] {
  min-width: 200px !important;
}

.font-weight-medium {
  font-weight: 500 !important;
}

.main_sidebar,
.main-pannel {
  height: calc(100vh - 55px) !important;
  overflow: auto;
}

// toster
.toast-container .toast {
  position: relative;
  &::before {
    content: '';
    font-family: 'Font Awesome 5 Free' !important;
    font-size: 20px;
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
  }

  &:hover {
    opacity: initial !important;
  }
}

.toast-container .toast {
  opacity: 1;
}
/* 
.toast-container > .toast-success {
  background-color: #000;
  color: #fff;
}
.toast-container > .toast-error {
  background-color: #e82630;
  color: #e9e9e9;
}
.toast-container > .toast-warning {
  background-color: #e78c14;
  color: rgb(236, 228, 228);
} */

/* // Loader start */
.loader1mk {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
/* // loader end */

/* You can add global styles to this file, and also import other style files */
span.fa.fa-eye-slash {
  float: right;
  margin-right: 10px;
  margin-top: -26px;
  position: relative;
  z-index: 2;
}
span.fa.fa-eye {
  float: right;
  margin-right: 10px;
  margin-top: -26px;
  position: relative;
  z-index: 2;
}
img.img-responsive.w100 {
  width: 100%;
  max-width: 10rem;
  display: block;
  margin: auto;
  margin-bottom: 1rem;
}
/* .modal-backdrop.show {
  opacity: 0.9 !important;
} */
.app-search input {
  color: #cecece;
  color: #cecece !important;
  position: relative;
  margin: 12px 15px 8px 5px;
  font-size: 16px;
  color: #626773;
  padding-left: 20px;
  padding-right: 40px;
  background: transparent;
  border: 1px solid rgba(122, 125, 132, 0.15);
  box-shadow: none;
  border-radius: 30px;
  font-weight: 600;
  width: 100%;
}
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgb(255 255 255 / 50%);
}
.btn-outline-primary:hover {
  color: #626773;
  background-color: #ffffff;
  border-color: none;
}
.btn-outline-primary {
  color: #626773;
}
button#dropdownBasic1 {
  border: none;
}
.d-inline-block.dropdown {
  padding: 8px 4px;
}
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #464040;
  background-color: #ffffff;
  border-color: none;
}
.sb1 {
  background: #009efb;
  position: fixed;
  width: 100%;
  top: 0px;
  z-index: 9999;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.22);
}
.top-not-cen {
  margin: 0 auto;
  display: table;
  padding-top: 14px !important;
}
.btn-noti {
  width: 35px;
  height: 23px;
  top: 18px;
  display: initial !important;
  color: #000;
  font-size: 20px;
  margin-right: 20px;
}
.top-user-pro {
  padding: 18px 20px;
  color: #494b4b;
  float: right;
}
.btn-noti span {
  position: absolute;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  top: 8px;
  height: 14px;
  border-radius: 10px;
  min-width: 15px;
  background: #4caf50;
  color: #fff;
  line-height: 14px;
  padding: 0px 2px;
  font-size: 11px;
  white-space: nowrap;
  -webkit-transform-origin: -10% center;
  -ms-transform-origin: -10% center;
  transform-origin: -10% center;
  font-family: tahoma;
  box-shadow: 0 0 0 1px #3d793f;
  text-align: center;
}
.btn-outline-primary.focus,
.btn-outline-primary:focus {
  box-shadow: 0 0 0 0.2rem rgb(37 61 82 / 0%) !important;
}

img.round-profile {
  border-radius: 50px;
  height: 1.6rem;
}
