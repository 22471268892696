/**
Application base files
**/
 

@import 'mixin';
@import 'responsive';
@import 'theme_color';
/** 
Override bootstrap components
**/


@import url('https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700');

li.no-data h5 {
    font-size: 14px;
    line-height: normal;
    font-weight: 500;
}

.td-text{
    margin: 0;
    *{
        display: inline;
        margin: 0;
    }
}

.loading-text{
    top:50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
}

p.loaderbg{
    margin-bottom: 0;
}

.action .btn {
   margin: 4px;
}

.la-ball-scale-multiple {
    display: none !important;
}

ngx-spinner{
    .overlay {
        // background-image: url(./../img/banner.jpg);
        background-size: cover;
        background-position: center center;
    }
}


.loader-box{
    display: inline-block;
    font-size: 30px;
    background-image: url(/assets/img/logo.jpg);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    vertical-align: top;
    transition: 0.3s color, 0.3s border, 0.3s transform, 0.3s opacity;

    [class*=loader-] {
        display: inline-block;
        width: 80px;
        height: 40px;
        background-color: transparent;
        color: inherit;
        vertical-align: middle;
        pointer-events: none;
    }

}


.loader-36 {
    position: relative;
    }
    .loader-36:before, .loader-36:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    }
    .loader-36:before {
    width: 1em;
    height: 1em;
    border: 0.1em solid currentcolor;
    border-radius: 50%;
    -webkit-animation: loader-36-1 1.15s infinite -0.3s;
    animation: loader-36-1 1.15s infinite -0.3s;
    }
    .loader-36:after {
    right: 0;
    bottom: 0;
    margin: auto;
    width: 0;
    height: 0;
    border: 0.1em solid currentcolor;
    border-radius: 50%;
    transform: translate(-0.2em);
    -webkit-animation: loader-36-2 4.6s infinite steps(1);
    animation: loader-36-2 4.6s infinite steps(1);
    }
    
    @-webkit-keyframes loader-36-1 {
    to {
    transform: rotateX(180deg);
    }
    }
    
    @keyframes loader-36-1 {
    to {
    transform: rotateX(180deg);
    }
    }
    @-webkit-keyframes loader-36-2 {
    0% {
    opacity: 0;
    }
    25% {
    opacity: 1;
    }
    50% {
    box-shadow: 0.2em 0 0 currentcolor;
    }
    75% {
    box-shadow: 0.2em 0 0 currentcolor, 0.4em 0 0 currentcolor;
    }
    }
    @keyframes loader-36-2 {
    0% {
    opacity: 0;
    }
    25% {
    opacity: 1;
    }
    50% {
    box-shadow: 0.2em 0 0 currentcolor;
    }
    75% {
    box-shadow: 0.2em 0 0 currentcolor, 0.4em 0 0 currentcolor;
    }
    }


    .loader-39 {
        position: relative;
        width: .15em;
        height: .15em;
        background-color: currentcolor;
        border-radius: 100%;
        animation: loader-39-1 30s infinite linear;
          &:before, &:after {
              content: '';
              border-radius: 100%;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
          }
          &:before {
            width: 215px;
            height: 70px;
              animation: loader-39-2 .8s linear infinite;
          }
          &:after {
            width: 70px;
            height: 215px;
              animation: loader-39-2 1.2s linear infinite;
          }
      }
      @keyframes loader-39-1 {
          0% {
              transform: rotate(0deg);
          }
          100% {
              transform: rotate(360deg);
          }
      }
      @keyframes loader-39-2 {
        0% {
          box-shadow: 0.04em -0.04em 0 0.02em currentcolor;
        }
        25% {
          box-shadow: 0.04em 0.04em 0 0.02em currentcolor;
        }
        50% {
          box-shadow: -0.04em 0.04em 0 0.02em currentcolor;
        }
        75% {
          box-shadow: -0.04em -0.04em 0 0.02em currentcolor;
        }
        100% {
          box-shadow: 0.04em -0.04em 0 0.02em currentcolor;
        }
      }


.md-drppicker .ranges ul li button.active{
    background-color: $primaryColor !important;
    color: #333 !important;
    outline: none !important;
}

.md-drppicker th.available.prev, .md-drppicker th.available.next {
background-color:  $primaryColor !important;
}

.md-drppicker th{
    color: #000 !important;
    border: 1px solid #ececec !important;
}

.md-drppicker td.active{
    background-color: $primaryColor !important;
    color: #333 !important;
    opacity: 1;
}

.datatable-header-cell-label {
    font-weight: 600;
}

.datatable-body-row{
    height: auto !important;
    datatable-body-cell {
        height: auto !important;
        padding: 7px 12px !important;
        display: inline-flex !important;
        flex-wrap:wrap !important;
        white-space: pre-wrap !important;
        align-items: center;
    }
}



angular-file-uploader{
    label.btn{
        margin: 0;
    }

    .resetBtn{
        margin-left: 7px !important;
    }

    div.row{
        margin: 0;
    }

    p.col-xs-3 {
        margin-bottom: 0;
    }

    p.text-success {
        font-size: 18px;
    }
}

.search-form{
    display: flex;
}

a{
    cursor: pointer;
}

body{
    background-image: linear-gradient(to right, rgb(0 158 251 / 16%), #ace0fe45);
}

input[type="password"], .password input{
    padding-right: 35px !important;
    background-image: none !important;
}

select.form-control{
 background-image: none !important;   
}

.main-pannel {
    float: left;
    width: 80%;
    padding: 0px 20px;
    margin-left: 20%;
}

.form-control, .table{
    font-size: 14px;
    line-height:  normal;
}

table{
    th,td{
        vertical-align: middle !important;
    }

    thead{
        background-color: #fff;
        color: #010101;
    }
}
.md-drppicker td.available.next, .md-drppicker th.available.next {
    border-radius: 0 !important;
}
.md-drppicker td.available.prev, .md-drppicker th.available.prev {
    border-radius: 0 !important;
}
.btn-mitra{
    font-size: 14px;
    font-weight: 500 !important;
    line-height: normal !important;
    padding: 8px 12px;
    color: #ffff !important;
}

.search-flex{
    display: inline-flex;
    input{
    border-radius: 0;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    outline: none !important;
    box-shadow: none !important;
    }

    >.btn{
        border-radius: 0;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
    }
}

.section-header {
    background-color: $primaryColor;
    color: #fff;
    padding: 8px 15px;
    line-height: inherit;
    font-size: 20px;
    // border-top-left-radius: 6px;
    margin-bottom: 15px;
    border-top-right-radius: 6px;
    // box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
}



.emptytd {
    text-align: center;
    padding: 50px 15px !important;

    .iconq {
        display: flex;
        width: 60px;
        align-items: center;
        height: 60px;
        border-radius: 4px;
        font-size: 20px;
        color: #fff;
        justify-content: center;
        margin: auto;
        background-color: $primaryColor;
    }

    .qtext {
        font-size: 18px;
        display: block;
        font-weight: 500;
        margin-top: 10px;
    }
}

.thumbnail{
    display: inline-block;
    position: relative;
    margin-bottom: 10px;
    margin-right: 10px;
    img{
        height: 60px;
        width:60px;
        object-fit: cover;
        cursor: pointer;
        object-position: center center;
        border-radius: 4px;
    }

    .close{
        position: absolute;
        font-size: 18px;
        top: -7px;
        color: #E91E63;
        right: -5px;
        opacity: 1;
        cursor: pointer;
    }
}

.form-row{
    *{

        .has-required{
            >label{
            &::after{
                content: "*";
                color:red;
                display: inline-block;
                margin-left: 4px;
            }
            }
        }

        label{
            font-weight: 600;
            font-size: 14px;
        }

        p.text {
            margin-bottom: 0;
            background-color: #f3f3f3;
            padding: 6px 10px;
            font-size: 14px;
            border-radius: 3px;
        }
    }
}

.addupdateform {
    .form-group{
        display: flex;

        >label{
            width: 100px;
            font-size: 14px;
            margin-top: 9px;
            text-align: right;
        }

        >section{
            width: calc(100% - 100px);
            padding-left: 10px;
        }
    }
}
/* .toast-container > .toast-success {
    background-color: #000;
    color: #fff;
  }
  .toast-container > .toast-error {
    background-color: #e82630;
    color: #e9e9e9;
  }
  .toast-container > .toast-warning {
    background-color: #e78c14;
    color: rgb(236, 228, 228);
  } */